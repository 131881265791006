import { Button } from 'antd';
import { useParams } from 'react-router-dom';

import { Header } from '../../components';

const PreviewPage = () => {
  const { id } = useParams();
  const handleDownload = () => {
    const anchor = document.createElement('a');
    anchor.href = `${process.env.REACT_APP_API_URL}/bucket/processing/${id}`;

    anchor.download = `Memora-love-${id}.mp4`; // Specify a filename for the download
    anchor.click(); // Programmatically click the anchor to trigger the download
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Header />
      <Button
        style={{
          marginTop: '130px',
          marginBottom: '20px',
          color: '#2c9886',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
        size="large"
        onClick={handleDownload}
      >
        Download!
      </Button>
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '1000px',
          paddingTop: '56.25%',
          overflow: 'hidden',
        }}
      >
        <video
          muted
          autoPlay
          playsInline
          preload="metadata"
          controls={
            window.innerWidth <= 768 ||
            /Mobi|Android/i.test(navigator.userAgent)
          }
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          src={`${process.env.REACT_APP_API_URL}/bucket/processing/${id}`}
        />
      </div>
    </div>
  );
};

export default PreviewPage;
