import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import queryClient from '../../../config';
import { Api } from '../../Api';

const useGetScheduledList = () => {
  const queryKey = ['scheduled-list'];
  const queryFn = async () => Api.Schedule.getScheduleList();
  const navigate = useNavigate();

  const { data, isLoading, isSuccess, isError, error, refetch } = useQuery({
    queryKey,
    queryFn,
    retry: false,
  });
  if ((error as any)?.status === 401) {
    Api.Auth.refreshToken()
      .then((response) => {
        if (response.accessToken) {
          localStorage.setItem('refresh_token', response.refreshToken);
          localStorage.setItem('access_token', response.accessToken);
          refetch();
        } else {
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('access_token');
          queryClient.invalidateQueries().then(() => {
            navigate('/login');
          });
        }
      })
      .catch(() => {
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        queryClient.invalidateQueries().then(() => {
          navigate('/login');
        });
      });
  }

  return {
    data,
    isLoading,
    refetch,
    isSuccess,
    isError,
    error,
  };
};

export default useGetScheduledList;
