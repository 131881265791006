import { AuthApi } from './AuthApi';
import { IndexApi } from './IndexApi';
import { ScheduleApi } from './ScheduleApi';

const Index = new IndexApi();
const Auth = new AuthApi();
const Schedule = new ScheduleApi();

export const Api = {
  Index,
  Auth,
  Schedule,
};
