import { get, post } from '../request';

export class ScheduleApi {
  async getScheduleList() {
    const apiUrl = `messaging/schedule/list`;

    const response = (await get({ apiUrl })) as any;
    return response.data;
  }

  async generateSchedule(props: any) {
    const apiUrl = `messaging/schedule`;

    const response = (await post({ apiUrl, body: { ...props } })) as any;
    return response.data;
  }

  async validateReceiver(props: any) {
    const apiUrl = `messaging/channel/profile`;

    const response = (await post({ apiUrl, body: { ...props } })) as any;
    return response.data;
  }

  async getChannelList() {
    const apiUrl = `messaging/channel/list`;

    const response = (await get({ apiUrl })) as any;

    return response.data;
  }
}
