import { useMutation } from '@tanstack/react-query';

import { Api } from '../../Api';

const useSignIn = () => {
  const queryFn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Api.Auth.signIn({ email, password });

  const { mutateAsync, isLoading, isError, error } = useMutation({
    mutationFn: queryFn,
  });

  return {
    signIn: mutateAsync,
    error,
    isError,
    isLoading,
  };
};

export default useSignIn;
