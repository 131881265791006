import { Button, Col, Empty, Pagination, Row } from 'antd';

import './index.css';

const GeneratedList = ({
  generatedPaginatedItems,
  generatedListPage,
  pageSize,
  generatedList,
  handleGeneratedPageChange,
  handleClick,
}: any) => (
  <div>
    {generatedPaginatedItems?.length ? (
      <>
        <Row gutter={[16, 16]}>
          {generatedPaginatedItems?.map((item: any) =>
            item.status === 'EXPORT_COMPLETED' ? (
              <Col
                className="gutter-row"
                xs={24}
                sm={12}
                md={8}
                lg={6}
                key={item.id}
              >
                {item.format.type !== 'mp4' ? (
                  <div className="responsive-container">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${item.attachmentName}`}
                      className="responsive-image"
                      alt="oikojko"
                    />
                  </div>
                ) : (
                  <div className="responsive-container">
                    <video
                      muted
                      playsInline
                      preload="metadata"
                      className="responsive-video"
                      controls={
                        window.innerWidth <= 768 ||
                        /Mobi|Android/i.test(navigator.userAgent)
                      }
                      src={`${process.env.REACT_APP_API_URL}/bucket/processing/${item.attachmentName}`}
                      onMouseEnter={(e) => {
                        if (e.currentTarget.paused) {
                          e.currentTarget.play();
                        }
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.pause();
                        e.currentTarget.currentTime = 0;
                      }}
                      onTouchStart={(e) => {
                        if (e.currentTarget.paused) {
                          e.currentTarget.play();
                        }
                      }}
                      onTouchEnd={(e) => {
                        e.currentTarget.pause();
                        e.currentTarget.currentTime = 0;
                      }}
                    />
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => {
                      handleClick(item.attachmentName, item['_id']);
                    }}
                    style={{ margin: '15px', width: 'min-content' }}
                  >
                    Select
                  </Button>
                </div>
              </Col>
            ) : (
              <Col className="gutter-row" xs={24} sm={12} md={8} lg={6}>
                <div className="responsive-container">
                  <div className="loader"></div>
                </div>
              </Col>
            ),
          )}
        </Row>
        <Pagination
          current={generatedListPage}
          pageSize={pageSize}
          total={generatedList?.length || 0}
          onChange={handleGeneratedPageChange}
          style={{
            marginTop: '30px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '40px',
          }}
        />
      </>
    ) : (
      <Empty description="Select the 'Templates' tab, choose the one you like and start generating gifts." />
    )}
  </div>
);

export default GeneratedList;
