import { useQuery } from '@tanstack/react-query';

import { Api } from '../../Api';

const useGetTemplateList = () => {
  const queryKey = ['template-list'];

  const queryFn = async () => Api.Index.getTemplateList();
  const { data, isLoading, isSuccess, isError, error } = useQuery({
    queryKey,
    queryFn,
    retry: false,
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};

export default useGetTemplateList;
