import axios from 'axios';

async function request({ apiUrl, opts }: { apiUrl: string; opts: any }) {
  const accessToken = localStorage.getItem('access_token') ?? '';

  const authHeaders = { Authorization: `Bearer ${accessToken}` };

  const config = {
    ...opts,
    credentials: 'same-origin',
    validateStatus: (status: number) => {
      if (status === 401) {
        return false;
      }
      return true;
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authHeaders,
      ...opts?.headers,
    },
  };

  const { data, status, statusText, headers } = await axios(
    `${process.env.REACT_APP_API_URL}/api/v1/${apiUrl}`,
    config,
  );

  return {
    statusText,
    status,
    data,
    headers,
  };
}

export async function get({
  apiUrl,
  params,
  otherOpts,
}: {
  apiUrl: string;
  params?: any;
  otherOpts?: any;
}) {
  const opts = {
    method: 'GET',
    params,
    ...otherOpts,
  };
  return request({ apiUrl, opts });
}

export async function post({
  apiUrl,
  params,
  body,
  otherOpts,
}: {
  apiUrl: string;
  params?: any;
  body?: any;
  otherOpts?: any;
}) {
  const opts = {
    method: 'POST',
    data: body,
    params,
    ...otherOpts,
  };
  return request({ apiUrl, opts });
}
