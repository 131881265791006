import { useMutation } from '@tanstack/react-query';

import { Api } from '../../Api';

const useSignUp = () => {
  const queryFn = async ({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => Api.Auth.signUp({ email, password, firstName, lastName });

  const { mutateAsync, isLoading, isError, error } = useMutation({
    mutationFn: queryFn,
  });

  return {
    signUp: mutateAsync,
    error,
    isError,
    isLoading,
  };
};

export default useSignUp;
