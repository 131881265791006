import {
  Modal,
  Image,
  Tabs,
  Pagination,
  Card,
  Button,
  Input,
  DatePicker,
  Dropdown,
  InputNumber,
  Row,
  Col,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Modal as CustomModal, GeneratedList } from '../../components';
import { Api } from '../../lib/Api';
import {
  useGetScheduledList,
  useGetTemplateList,
  useGetUserDetails,
} from '../../lib/hooks';
import useGetChannelList from '../../lib/hooks/useGetChannelList';
import useGetGenerateList from '../../lib/hooks/useGetGenerateList';
import './index.css';

type ValueList = {
  WHATSAPP: string;
  TELEGRAM: string;
  EMAIL: string;
};

const Profile = () => {
  const pageSize = 12;
  const valueList: ValueList = {
    WHATSAPP: 'WhatsApp',
    TELEGRAM: 'Telegram',
    EMAIL: 'Email',
  };
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isScheduledModalOpen, setIsScheduledModalOpen] = useState(false);
  const [receiverValidated, setReceiverValidated] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [scheduledReceiver, setScheduledReceiver] = useState<any>(null);
  const [scheduledMessage, setScheduledMessage] = useState(null);
  const [scheduledTime, setScheduledTime] = useState<any>(null);
  const [scheduledMedia, setScheduledMedia] = useState(null);
  const [canvaId, setCanvaId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [generatedListPage, setGeneratedListPage] = useState(1);
  const [scheduledPage, setScheduledPage] = useState(1);

  const { data: templateList } = useGetTemplateList();
  const { data: scheduledList, refetch: refetchScheduled } =
    useGetScheduledList();
  const { data: channelList } = useGetChannelList();
  const { data: generatedList, refetch } = useGetGenerateList();
  const { userDetails } = useGetUserDetails();
  const closeModal = () => {
    setIsOpen(false);
    setSelectedItem(null);
  };

  const notificationEvent = ({
    type,
    content,
  }: {
    type: any;
    content: string;
  }) => {
    messageApi.open({ type, content });
  };

  const closeScheduledModal = () => {
    setGeneratedListPage(1);
    setIsScheduledModalOpen(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const allCompleted = generatedList?.every(
        (item: any) =>
          item.status === 'EXPORT_COMPLETED' || item.status === 'EXPORT_FAILED',
      );

      if (!allCompleted) {
        refetch();
      } else {
        clearInterval(intervalId); // Stop the interval
      }
    }, 3000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [generatedList, refetch]);

  const paginatedItems =
    templateList?.length &&
    [...templateList]?.sort((a: any, b: any) => a.id - b.id)
      ? [...templateList]?.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize,
        )
      : [];

  const generatedPaginatedItems =
    generatedList?.length &&
    [...generatedList]?.sort((a: any, b: any) => a.id - b.id)
      ? [...generatedList]?.slice(
          (generatedListPage - 1) * pageSize,
          generatedListPage * pageSize,
        )
      : [];

  const scheduledPaginatedItems =
    scheduledList?.length &&
    [...scheduledList]?.sort((a: any, b: any) => a['_id'] - b['_id'])
      ? [...scheduledList]?.slice((scheduledPage - 1) * 3, scheduledPage * 3)
      : [];

  const validateReceiver = () => {
    setReceiverValidated(false);
    Api.Schedule.validateReceiver({
      receiver: `+${scheduledReceiver}`,
      channelType: selectedChannel,
    }).then((resp) => {
      if (resp === true) {
        setReceiverValidated(true);
      } else {
        setReceiverValidated(false);
      }
    });
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleGeneratedPageChange = (page: number) => {
    setGeneratedListPage(page);
  };

  const handleScheduledPageChange = (page: number) => {
    setScheduledPage(page);
  };

  const handleClick = (id: any) => {
    window.open(`${window.location.origin}/preview/${id}`);
  };

  return (
    <div style={{ padding: '50px', marginTop: '75px' }}>
      {contextHolder}
      <Header />
      <div>
        <span
          style={{
            fontSize: '40px',
            color: '#F81D3D',
            fontFamily: '"Fredoka One", cursive',
            fontWeight: '400',
          }}
        >
          Welcome,
        </span>{' '}
        <span
          style={{
            fontSize: '40px',
            fontFamily: '"Fredoka One", cursive',
            fontWeight: '400',
          }}
        >
          {userDetails?.firstName} {userDetails?.lastName}
        </span>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        <div>
          <span
            style={{
              fontSize: '20px',
              color: '#F81D3D',
              fontFamily: '"Fredoka One", cursive',
              fontWeight: '400',
            }}
          >
            Balance:
          </span>{' '}
          <span
            style={{
              fontSize: '20px',
              fontFamily: '"Fredoka One", cursive',
              fontWeight: '400',
            }}
          >
            {userDetails?.balance / 100}
          </span>
        </div>
        <Button onClick={() => navigate('/price-list')}>
          <div className="plus-icon"></div>
        </Button>{' '}
      </div>
      <Tabs
        defaultActiveKey="1"
        tabPosition="top"
        style={{ height: 220 }}
        onChange={(e) => {
          setGeneratedListPage(1);
          setCurrentPage(1);
          setScheduledPage(1);
          if (e === 'generated') {
            refetch();
          }
        }}
        items={[
          {
            label: 'Generated',
            key: 'generated',
            children: (
              <GeneratedList
                generatedPaginatedItems={generatedPaginatedItems}
                generatedListPage={generatedListPage}
                pageSize={pageSize}
                generatedList={generatedList}
                handleGeneratedPageChange={handleGeneratedPageChange}
                handleClick={handleClick}
              />
            ),
          },
          {
            label: 'Templates',
            key: 'templates',
            children: (
              <div>
                <Row gutter={[16, 16]}>
                  {paginatedItems?.map((item: any) => (
                    <Col
                      className="gutter-row"
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      key={item.id}
                    >
                      {item.title.includes('STATIC') ? (
                        <Image
                          src={`${process.env.REACT_APP_API_URL}/bucket/template/${item.thumbnail}`}
                          style={{ maxHeight: '400px', width: '100%' }}
                        />
                      ) : (
                        <div className="responsive-video-container">
                          <video
                            muted
                            playsInline
                            controls={
                              window.innerWidth <= 768 ||
                              /Mobi|Android/i.test(navigator.userAgent)
                            }
                            preload="metadata"
                            className="responsive-video"
                            onMouseEnter={(e) => {
                              if (e.currentTarget.paused) {
                                e.currentTarget.play();
                              }
                            }}
                            onMouseLeave={(e) => {
                              e.currentTarget.pause();
                              e.currentTarget.currentTime = 0;
                            }}
                            onTouchStart={(e) => {
                              if (e.currentTarget.paused) {
                                e.currentTarget.play();
                              }
                            }}
                            onTouchEnd={(e) => {
                              e.currentTarget.pause();
                              e.currentTarget.currentTime = 0;
                            }}
                            src={`${process.env.REACT_APP_API_URL}/bucket/template/${item.thumbnail}`}
                          />
                        </div>
                      )}
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Button
                          onClick={() => {
                            setIsOpen(true);
                            setSelectedItem(item.id);
                          }}
                          style={{ margin: '15px', width: 'min-content' }}
                        >
                          Select Template
                        </Button>
                      </div>
                    </Col>
                  ))}
                </Row>
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={templateList?.length || 0}
                  onChange={handlePageChange}
                  style={{
                    marginTop: '30px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '40px',
                  }}
                />
                <Modal
                  title="Fill out the form and we take care of the rest!"
                  open={isOpen}
                  centered
                  onCancel={closeModal}
                  footer={false}
                >
                  <CustomModal
                    selectedItem={selectedItem}
                    onCancel={closeModal}
                    notificationEvent={notificationEvent}
                  />
                </Modal>
              </div>
            ),
          },
          {
            label: 'Scheduled',
            key: 'scheduled',
            children: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '25px',
                  paddingBottom: '50px',
                }}
              >
                <Modal
                  title="Select from one of your generated items."
                  open={isScheduledModalOpen}
                  centered
                  onCancel={closeScheduledModal}
                  footer={false}
                  width="100%"
                >
                  <GeneratedList
                    generatedPaginatedItems={generatedPaginatedItems}
                    generatedListPage={generatedListPage}
                    pageSize={pageSize}
                    generatedList={generatedList}
                    handleGeneratedPageChange={handleGeneratedPageChange}
                    handleClick={(id: any, canvaPropId: any) => {
                      setScheduledMedia(id);
                      setCanvaId(canvaPropId);
                      closeScheduledModal();
                    }}
                  />
                </Modal>
                <Card
                  title={
                    <div
                      style={{
                        color: 'rgb(248, 29, 61)',
                        fontFamily: '"Fredoka One", cursive',
                        fontWeight: 400,
                      }}
                    >
                      Schedule a gift
                    </div>
                  }
                  style={{ width: '100%' }}
                  extra={
                    <Button
                      disabled={
                        !scheduledMedia || selectedChannel === 'EMAIL'
                          ? !scheduledReceiver
                          : !receiverValidated ||
                            !selectedChannel ||
                            !scheduledTime
                      }
                      onClick={() => {
                        Api.Schedule.generateSchedule({
                          channelType: selectedChannel,
                          receiver: scheduledReceiver,
                          message: scheduledMessage,
                          canvaGeneratedId: canvaId,
                          scheduledAt: scheduledTime,
                        })
                          .then(() => {
                            notificationEvent({
                              type: 'success',
                              content: 'Item successfully scheduled',
                            });
                            setScheduledPage(1);
                            setSelectedChannel(null);
                            setScheduledMessage(null);
                            setScheduledMedia(null);
                            setScheduledTime(null);
                            setScheduledReceiver(null);
                            refetchScheduled();
                          })
                          .catch(() => {
                            notificationEvent({
                              type: 'error',
                              content: 'An error has occurred',
                            });
                          });
                      }}
                    >
                      Submit
                    </Button>
                  }
                >
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '15px',
                      whiteSpace: 'nowrap',
                      alignItems: 'center',
                    }}
                  >
                    Channel Type:{' '}
                    <Dropdown
                      trigger={['click']}
                      menu={{
                        items: channelList?.map((val: any) => ({
                          key: val.type,
                          label: valueList[val.type as keyof ValueList],
                        })),
                        onClick: (e) => {
                          setScheduledReceiver(null);
                          setSelectedChannel(e.key as any);
                        },
                      }}
                    >
                      <Button>
                        {valueList[
                          selectedChannel as unknown as keyof ValueList
                        ] || 'Select An Channel'}
                      </Button>
                    </Dropdown>
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '15px',
                      marginTop: '15px',
                      whiteSpace: 'nowrap',
                      alignItems: 'center',
                    }}
                  >
                    Receiver:{' '}
                    {selectedChannel === 'EMAIL' ? (
                      <Input
                        onChange={(val) =>
                          setScheduledReceiver(`${val.target.value}`)
                        }
                        placeholder="Receiver"
                        value={scheduledReceiver}
                        style={{ maxWidth: '300px' }}
                        type="email"
                      />
                    ) : (
                      <InputNumber
                        addonBefore="+"
                        placeholder="Receiver"
                        controls={false}
                        value={scheduledReceiver}
                        onChange={(val) => setScheduledReceiver(`${val}`)}
                        onBlur={validateReceiver}
                      />
                    )}
                  </div>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Message:{' '}
                    <Input.TextArea
                      placeholder="Message"
                      autoSize
                      value={scheduledMessage || ''}
                      onChange={(e) =>
                        setScheduledMessage(e.target.value as any)
                      }
                    />
                  </p>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '15px',
                      whiteSpace: 'nowrap',
                      alignItems: 'center',
                    }}
                  >
                    Schedule Date:{' '}
                    <DatePicker
                      showTime
                      onChange={(_, dateString) => {
                        setScheduledTime(dateString);
                      }}
                    />
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      whiteSpace: 'nowrap',
                      marginTop: '15px',
                    }}
                  >
                    <p>
                      Media:{' '}
                      <Button onClick={() => setIsScheduledModalOpen(true)}>
                        Select Media
                      </Button>
                    </p>
                    {scheduledMedia && (
                      <video
                        muted
                        playsInline
                        preload="metadata"
                        controls={
                          window.innerWidth <= 768 ||
                          /Mobi|Android/i.test(navigator.userAgent)
                        }
                        autoPlay
                        style={{
                          maxHeight: '150px',
                        }}
                        onClick={() => handleClick(scheduledMedia)}
                        src={`${process.env.REACT_APP_API_URL}/bucket/processing/${scheduledMedia}`}
                      />
                    )}
                  </div>
                </Card>

                {scheduledPaginatedItems?.map((schedule: any) => {
                  const date = new Date(schedule.scheduledAt);

                  const title = date.toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZoneName: 'short',
                  });
                  return (
                    <div>
                      <Card
                        title={
                          <div
                            style={{
                              fontFamily: '"Fredoka One", cursive',
                              fontWeight: 400,
                            }}
                          >
                            {title}
                          </div>
                        }
                        style={{ width: '100%' }}
                      >
                        <p>
                          Channel Type:{' '}
                          {
                            valueList[
                              schedule.channelType as unknown as keyof ValueList
                            ]
                          }
                        </p>
                        <p>
                          Receiver:{' '}
                          {schedule.channelType !== 'EMAIL' ? '+' : ''}
                          {schedule.receiver}
                        </p>
                        {schedule?.message && (
                          <p>Message: {schedule.message}</p>
                        )}
                        <p>Media: </p>
                        {generatedList && schedule && (
                          <video
                            muted
                            playsInline
                            preload="metadata"
                            controls={
                              window.innerWidth <= 768 ||
                              /Mobi|Android/i.test(navigator.userAgent)
                            }
                            autoPlay
                            style={{
                              maxHeight: '150px',
                            }}
                            onClick={() =>
                              handleClick(
                                generatedList?.find(
                                  (res: any) =>
                                    res['_id'] === schedule.canvaGeneratedId,
                                ).attachmentName,
                              )
                            }
                            src={`${process.env.REACT_APP_API_URL}/bucket/processing/${generatedList?.find((res: any) => res['_id'] === schedule.canvaGeneratedId).attachmentName}`}
                          />
                        )}
                      </Card>
                    </div>
                  );
                })}
                <Pagination
                  current={scheduledPage}
                  pageSize={3}
                  total={scheduledList?.length || 0}
                  onChange={handleScheduledPageChange}
                  style={{
                    marginTop: '30px',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '40px',
                  }}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Profile;
