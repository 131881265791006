import { get, post } from '../request';

export class AuthApi {
  async getUserDetails() {
    const apiUrl = `auth/me`;

    const response = (await get({ apiUrl })) as any;
    return response.data;
  }

  async signUp({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) {
    const apiUrl = `auth/sign-up`;

    const response = (await post({
      apiUrl,
      body: { email, password, firstName, lastName },
    })) as any;
    return response.data;
  }

  async signIn({ email, password }: { email: string; password: string }) {
    const apiUrl = `auth/sign-in`;

    const response = (await post({ apiUrl, body: { email, password } })) as any;
    return response.data;
  }

  async forgetPassword() {
    const apiUrl = `auth/forget-init`;

    const response = (await post({ apiUrl })) as any;
    return response.data;
  }

  async refreshToken() {
    const apiUrl = `auth/refresh`;
    const token = localStorage.getItem('refresh_token');

    const response = (await post({
      apiUrl,
      body: { refreshToken: token },
    })) as any;
    return response.data;
  }
}
