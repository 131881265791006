import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import queryClient from './config';
import {
  MainScreen,
  Login,
  Register,
  Profile,
  PreviewPage,
  Checkout,
  PriceList,
} from './pages';
import TermsAndConditions from './pages/TermsAndConditions';

const App = () => (
  <div>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/main" element={<MainScreen />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/preview/:id" element={<PreviewPage />} />
          <Route path="/price-list" element={<PriceList />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="*" element={<Navigate to="/main" replace />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </div>
);

export default App;
