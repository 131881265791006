import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import queryClient from '../../../config';
import { Api } from '../../Api';

const useGenerate = ({ id }: { id: string }) => {
  const queryFn = async (props: any) => Api.Index.generateItem(props, id);
  const navigate = useNavigate();

  const { mutateAsync, isLoading, isError, error } = useMutation({
    mutationFn: queryFn,
  });
  if ((error as any)?.status === 401) {
    Api.Auth.refreshToken()
      .then((response) => {
        if (response.accessToken) {
          localStorage.setItem('refresh_token', response.refreshToken);
          localStorage.setItem('access_token', response.accessToken);
        } else {
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('access_token');
          queryClient.invalidateQueries().then(() => {
            navigate('/login');
          });
        }
      })
      .catch(() => {
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        queryClient.invalidateQueries().then(() => {
          navigate('/login');
        });
      });
  }

  return {
    generate: mutateAsync,
    error,
    isError,
    isLoading,
  };
};

export default useGenerate;
