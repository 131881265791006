import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import queryClient from '../../../config';
import { Api } from '../../Api';

const useGetUserDetails = () => {
  const queryKey = ['user-details'];
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access_token');

  const queryFn = async () => Api.Auth.getUserDetails();
  const { data, isLoading, isSuccess, isError, error, refetch } = useQuery({
    queryKey,
    queryFn,
    retry: false,
    enabled: !!accessToken, // Hook is enabled only if access_token is present
  });

  if ((error as any)?.status === 401 && localStorage.getItem('refresh_token')) {
    Api.Auth.refreshToken()
      .then((response) => {
        if (response.accessToken) {
          localStorage.setItem('refresh_token', response.refreshToken);
          localStorage.setItem('access_token', response.accessToken);
          refetch();
        } else {
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('access_token');
          queryClient.invalidateQueries().then(() => {
            navigate('/login');
          });
        }
      })
      .catch(() => {
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        queryClient.invalidateQueries().then(() => {
          navigate('/login');
        });
      });
  }

  return {
    userDetails: data,
    isLoading,
    isSuccess,
    isError,
    error,
  };
};

export default useGetUserDetails;
