import { Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Header } from '../../components';

const gridStyle: React.CSSProperties = {
  width: '100%',
  minHeight: '150px',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  fontSize: '30px',
  color: '#F81D3D',
  fontFamily: '"Fredoka One", cursive',
  fontWeight: '400',
};

const PriceList = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div style={{ marginTop: '100px', padding: '50px' }}>
        <Card
          title={
            <div>
              <span
                style={{
                  fontSize: '30px',
                  color: '#F81D3D',
                  fontFamily: '"Fredoka One", cursive',
                  fontWeight: '400',
                }}
              >
                Price
              </span>{' '}
              <span
                style={{
                  fontSize: '30px',
                  fontFamily: '"Fredoka One", cursive',
                  fontWeight: '400',
                }}
              >
                list
              </span>
            </div>
          }
        >
          <Row gutter={[16, 16]}>
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={6}
              onClick={() => {
                navigate('/checkout?amount=200');
              }}
            >
              <Card.Grid style={gridStyle}>2$</Card.Grid>
            </Col>{' '}
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={6}
              onClick={() => {
                navigate('/checkout?amount=500');
              }}
            >
              <Card.Grid style={gridStyle}>5$</Card.Grid>
            </Col>{' '}
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={6}
              onClick={() => {
                navigate('/checkout?amount=1000');
              }}
            >
              <Card.Grid style={gridStyle}>10$</Card.Grid>
            </Col>{' '}
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              lg={6}
              onClick={() => {
                navigate('/checkout?amount=2000');
              }}
            >
              <Card.Grid style={gridStyle}>20$</Card.Grid>
            </Col>
          </Row>
        </Card>
      </div>
      <div id="checkout" />
    </div>
  );
};

export default PriceList;
