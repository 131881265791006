import { Header } from '../../components';

const TermsAndConditions = () => (
  <div>
    <Header />
    <div style={{ padding: '60px', marginTop: '100px' }}>
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        **Terms and Conditions**{' '}
      </span>{' '}
      <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **1. Introduction**{' '}
      </span>
      Welcome to Memora, a platform for generating personalized Christmas
      congratulations using AI and scheduling their delivery via Telegram,
      WhatsApp, or email. By accessing or using our Service, you agree to these
      Terms and Conditions. Please read them carefully.
      <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **2. Eligibility**
      </span>{' '}
      You must be at least 18 years old to use this Service. By using the
      Service, you confirm that you meet this age requirement and that you have
      the authority to agree to these terms. <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **3. Use of the Service**{' '}
      </span>
      - The Service allows users to generate personalized Christmas messages
      based on templates. - Users can schedule these messages to be sent to
      recipients via Telegram, WhatsApp, or email on a specified date. - Users
      are responsible for ensuring the accuracy of recipient information and the
      scheduled delivery date. <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **4. Payment**
      </span>
      - Each message generation incurs a fee, as detailed on the pricing page. -
      Payments must be made in advance via the accepted payment methods. - All
      sales are final. Refunds will only be issued in cases where the Service
      fails to deliver due to technical errors directly attributable to us.{' '}
      <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **5. User Responsibilities**{' '}
      </span>
      - Users are responsible for providing accurate and lawful content. - The
      Service must not be used for sending spam, abusive, or offensive messages.
      - Users agree not to misuse the Service in any way that could harm its
      functionality or violate applicable laws. <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **6. Privacy**{' '}
      </span>{' '}
      - We collect and use your data in accordance with our Privacy Policy. - By
      using the Service, you consent to the collection and use of information as
      outlined in our Privacy Policy. <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        **7. Delivery**{' '}
      </span>{' '}
      - While we strive for timely delivery, we cannot guarantee delivery at the
      exact scheduled time due to technical or network issues. - Users should
      ensure recipient information is accurate to avoid delivery failures.{' '}
      <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **8. Intellectual Property**{' '}
      </span>
      - All templates and AI-generated content provided by the Service remain
      the property of Memora. - Users are granted a non-exclusive,
      non-transferable license to use the generated content for personal
      purposes. <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **9. Limitation of Liability**
      </span>{' '}
      - The Service is provided "as is" without any warranties of any kind. - We
      are not liable for indirect, incidental, or consequential damages arising
      from the use of the Service. <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        {' '}
        **10. Termination**{' '}
      </span>
      - We reserve the right to suspend or terminate access to the Service for
      any user who violates these Terms and Conditions. - Termination does not
      entitle the user to a refund. <br />
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        **11. Changes to the Terms**
      </span>{' '}
      - We may update these Terms and Conditions from time to time. - Continued
      use of the Service constitutes acceptance of the updated Terms. <br />{' '}
      <span
        style={{
          fontSize: '20px',
          color: '#F81D3D',
          fontFamily: '"Fredoka One", cursive',
          fontWeight: '400',
        }}
      >
        **12. Contact Information**{' '}
      </span>
      - For questions or concerns about these Terms, contact us at
      account@memora.love.
    </div>
  </div>
);

export default TermsAndConditions;
