import { useNavigate } from 'react-router-dom';

import './index.css';

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className="custom-header">
      <img
        className="brand-logo-light"
        src="/assets/images/Logo.svg"
        alt=""
        width="222"
        height="44"
        onClick={() => navigate('/main')}
      />
    </div>
  );
};

export default Header;
