import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Dynamically load the first script (script.js)
const script = document.createElement('script');
script.src = `${process.env.PUBLIC_URL}/assets/static/script.js`;
script.async = true;

// After script.js is loaded, load core.min.js
script.onload = () => {
  const script2 = document.createElement('script');
  script2.src = `${process.env.PUBLIC_URL}/assets/static/core.min.js`;
  script2.async = true;
  document.body.appendChild(script2);
};

document.body.appendChild(script); // Append script.js first
