import { get, post } from '../request';

export class IndexApi {
  async getTemplateList() {
    const apiUrl = `canva/template/list`;

    const response = (await get({ apiUrl })) as any;
    return response.data;
  }

  async generateItem(props: any, id: string) {
    const apiUrl = `canva/template/${id}/generate`;

    const response = (await post({ apiUrl, body: { ...props } })) as any;
    return response.data;
  }

  async fetchClientSecret(props: any) {
    const apiUrl = `stripe/payment/checkout`;

    const response = (await post({ apiUrl, body: { ...props } })) as any;
    return response.data;
  }

  async getGeneratedList() {
    const apiUrl = `canva/generate/list`;

    const response = (await get({ apiUrl })) as any;

    return response.data;
  }

  async getDataset(id: string) {
    const apiUrl = `canva/template/${id}/dataset`;

    const response = (await get({ apiUrl })) as any;
    return response.data;
  }

  async uploadAsset(props: any) {
    const apiUrl = `canva/asset/upload`;
    const formData = new FormData();
    formData.append('files', props);

    const response = (await post({
      apiUrl,
      body: formData,
      otherOpts: {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    })) as any;
    return response.data;
  }
}
