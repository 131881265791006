import { Checkbox } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from '../../components';
import useSignUp from '../../lib/hooks/useRegister';
import './index.css';

const Register = () => {
  const [agreedWithTerms, setAgreedWithTerms] = useState(false);
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const { signUp } = useSignUp();
  const navigate = useNavigate();

  const onSubmit = (e: any) => {
    e.preventDefault();
    const emailInput = (
      document.getElementById('register-email') as HTMLInputElement
    )?.value;
    const passwordInput = (
      document.getElementById('register-password') as HTMLInputElement
    )?.value;

    const firstNameInput = (
      document.getElementById('register-firstName') as HTMLInputElement
    )?.value;

    const lastNameInput = (
      document.getElementById('register-lastName') as HTMLInputElement
    )?.value;

    if (
      emailInput &&
      passwordInput &&
      firstNameInput &&
      lastNameInput &&
      agreedWithTerms
    ) {
      signUp({
        email: emailInput,
        password: passwordInput,
        firstName: firstNameInput,
        lastName: lastNameInput,
      }).then((response) => {
        localStorage.setItem('access_token', response.accessToken);
        localStorage.setItem('refresh_token', response.refreshToken);
        navigate('/main');
      });
    } else {
      setInvalidCredentials(true);
    }
  };

  return (
    <div
      style={{
        backgroundImage:
          "url('/assets/images/landing-christmas-01-1920x1080.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Header />
      <div className="col-md-6 col-xl-5 col-xxl-4">
        <div
          className="box-form-2 novi-bg fadeInRight animated"
          data-caption-animate="fadeInRight"
          data-caption-delay="100"
          style={{ maxWidth: '100%' }}
        >
          <h4 style={{ color: 'white' }}>Register</h4>
          <form
            className="rd-form rd-mailform"
            data-form-output="form-output-global"
            data-form-type="contact"
            onSubmit={onSubmit}
          >
            <div className="form-wrap">
              <input
                className="form-input form-control-has-validation"
                id="register-email"
                type="email"
                name="email"
                style={{ background: 'white', color: '#495057' }}
                placeholder="Email Address"
                onChange={() => {
                  setInvalidCredentials(false);
                }}
              />
            </div>
            <div className="form-wrap">
              <input
                className="form-input form-control-has-validation"
                id="register-firstName"
                name="firstName"
                style={{ background: 'white', color: '#495057' }}
                placeholder="First Name"
                onChange={() => {
                  setInvalidCredentials(false);
                }}
              />
            </div>
            <div className="form-wrap">
              <input
                className="form-input form-control-has-validation"
                id="register-lastName"
                type="text"
                name="lastName"
                style={{ background: 'white', color: '#495057' }}
                placeholder="Last Name"
                onChange={() => {
                  setInvalidCredentials(false);
                }}
              />
            </div>
            <div className="form-wrap">
              <input
                className="form-input form-control-has-validation"
                id="register-password"
                type="password"
                name="password"
                style={{ background: 'white', color: '#495057' }}
                placeholder="Password"
                onChange={() => {
                  setInvalidCredentials(false);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: '30px',
                  gap: '20px',
                  color: 'white',
                  fontSize: '20px',
                  fontFamily: '"Fredoka One", cursive',
                  fontWeight: '400',
                }}
              >
                <Checkbox
                  onChange={() => setAgreedWithTerms(!agreedWithTerms)}
                />
                <div>
                  I agree with{' '}
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/terms-and-conditions`,
                      )
                    }
                  >
                    Terms and Conditions
                  </span>
                </div>
              </div>
            </div>
            <button
              className="button button-block button-secondary button-nuka"
              type="submit"
            >
              Register <span className="button-overlay"></span>
            </button>
          </form>
          {invalidCredentials && (
            <div className="unit unit-spacing-6 align-items-center">
              <div className="unit-left">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  style={{ color: 'white' }}
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="M15 0C12.9297 0 10.9863 0.400391 9.16992 1.20117C7.35352 1.98242 5.76172 3.05664 4.39453 4.42383C3.04688 5.77148 1.97266 7.35352 1.17188 9.16992C0.390625 10.9863 0 12.9297 0 15C0 17.0703 0.390625 19.0137 1.17188 20.8301C1.97266 22.6465 3.04688 24.2383 4.39453 25.6055C5.76172 26.9531 7.35352 28.0176 9.16992 28.7988C10.9863 29.5996 12.9297 30 15 30C17.0703 30 19.0137 29.5996 20.8301 28.7988C22.6465 28.0176 24.2285 26.9531 25.5762 25.6055C26.9434 24.2383 28.0176 22.6465 28.7988 20.8301C29.5996 19.0137 30 17.0703 30 15C30 12.9297 29.5996 10.9863 28.7988 9.16992C28.0176 7.35352 26.9434 5.77148 25.5762 4.42383C24.2285 3.05664 22.6465 1.98242 20.8301 1.20117C19.0137 0.400391 17.0703 0 15 0ZM15 4.6875C15.5078 4.6875 15.9473 4.87305 16.3184 5.24414C16.6895 5.61523 16.875 6.05469 16.875 6.5625C16.875 7.07031 16.6895 7.50977 16.3184 7.88086C15.9473 8.25195 15.5078 8.4375 15 8.4375C14.4922 8.4375 14.0527 8.25195 13.6816 7.88086C13.3105 7.50977 13.125 7.07031 13.125 6.5625C13.125 6.05469 13.3105 5.61523 13.6816 5.24414C14.0527 4.87305 14.4922 4.6875 15 4.6875ZM18.75 25.3125H11.25C10.9766 25.3125 10.752 25.2246 10.5762 25.0488C10.4004 24.873 10.3125 24.6484 10.3125 24.375C10.3125 24.1016 10.4004 23.877 10.5762 23.7012C10.752 23.5254 10.9766 23.4375 11.25 23.4375H14.0625V13.125H11.25C10.9766 13.125 10.752 13.0371 10.5762 12.8613C10.4004 12.6855 10.3125 12.4609 10.3125 12.1875C10.3125 11.9141 10.4004 11.6895 10.5762 11.5137C10.752 11.3379 10.9766 11.25 11.25 11.25H15C15.2734 11.25 15.498 11.3379 15.6738 11.5137C15.8496 11.6895 15.9375 11.9141 15.9375 12.1875V23.4375H18.75C19.0234 23.4375 19.248 23.5254 19.4238 23.7012C19.5996 23.877 19.6875 24.1016 19.6875 24.375C19.6875 24.6484 19.5996 24.873 19.4238 25.0488C19.248 25.2246 19.0234 25.3125 18.75 25.3125Z"></path>
                </svg>
              </div>
              <div className="unit-body">
                <p className="small" style={{ color: 'white' }}>
                  Please fill out all the form inputs accordingly and make sure
                  the password has a minimum of 8 characters.{' '}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
