import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Header } from '../../components';
import { Api } from '../../lib/Api';

const stripePromise = loadStripe(
  'pk_live_51QU2lvLcqHLA0k053dhTUf92IqxgKSVjDOaQqZ65kGmVzn5j6lTD38baR8UzBGwzxoFd5njVfpm6EsU1qq3we7kK001U8aH9Wr',
);

const Checkout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const checkoutInstanceRef = useRef<any>(null); // Store checkout instance

  const paramValue = queryParams.get('amount'); // Replace 'paramName' with your query key
  useEffect(() => {
    const initStripe = async () => {
      const stripe = await stripePromise;
      const clientSecret = await Api.Index.fetchClientSecret({
        amount: paramValue,
        returnUrl: `${process.env.REACT_APP_API_URL}/main`,
      });

      checkoutInstanceRef.current = await stripe?.initEmbeddedCheckout({
        fetchClientSecret: () => clientSecret,
      });

      checkoutInstanceRef.current.mount('#checkout');
    };

    initStripe();

    return () => {
      if (checkoutInstanceRef.current) {
        checkoutInstanceRef.current.destroy(); // Unmount the embedded checkout
        checkoutInstanceRef.current = null; // Clear the reference
      }
    };
  }, [paramValue]);
  return (
    <div>
      <Header />
      <div id="checkout" style={{ marginTop: '110px' }} />
    </div>
  );
};

export default Checkout;
