import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';
import { useGetUserDetails } from '../../lib/hooks';

const MainScreen = () => {
  const { userDetails } = useGetUserDetails();
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${process.env.PUBLIC_URL}/assets/static/script.js`;
    script.async = true;

    script.onload = () => {
      const script2 = document.createElement('script');
      script2.src = `${process.env.PUBLIC_URL}/assets/static/core.min.js`;
      script2.async = true;
      document.body.appendChild(script2);
    };

    document.body.appendChild(script); // Append script.js first
  }, []);

  const timeLeft = useMemo(() => {
    const now = new Date();
    const newYear2025 = new Date('2025-01-01T00:00:00');
    // @ts-ignore
    const diffInMilliseconds = newYear2025 - now;

    if (diffInMilliseconds <= 0) {
      return { days: '0', hours: '0', minutes: '0' };
    }

    const minutes = Math.floor((diffInMilliseconds / (1000 * 60)) % 60);
    const hours = Math.floor((diffInMilliseconds / (1000 * 60 * 60)) % 24);
    const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return {
      days: days.toString(),
      hours: hours.toString(),
      minutes: minutes.toString(),
    };
  }, []);

  return (
    <div>
      <div>
        <div className="preloader loaded">
          <div className="preloader-body">
            <div className="cssload-container">
              <div className="cssload-speeding-wheel"></div>
            </div>
          </div>
        </div>
        <div className="page animated" style={{ animationDuration: '500ms' }}>
          <header className="section page-header" id="home">
            <div className="rd-navbar-wrap" style={{ height: 'auto' }}>
              <nav
                className="rd-navbar rd-navbar-promotion rd-navbar-6 context-dark rd-navbar-original rd-navbar-static"
                style={{ background: 'white' }}
                data-layout="rd-navbar-static"
                data-sm-layout="rd-navbar-static"
                data-md-layout="rd-navbar-static"
                data-lg-layout="rd-navbar-static"
                data-xl-layout="rd-navbar-static"
                data-xxl-layout="rd-navbar-static"
                data-md-device-layout="rd-navbar-static"
                data-lg-device-layout="rd-navbar-static"
                data-xl-device-layout="rd-navbar-static"
                data-xxl-device-layout="rd-navbar-static"
                data-lg-stick-up-offset="46px"
                data-xl-stick-up-offset="46px"
                data-xxl-stick-up-offset="46px"
                data-lg-stick-up="true"
                data-xl-stick-up="true"
                data-xxl-stick-up="true"
                data-lg-auto-height="false"
                data-xl-auto-height="false"
                data-xxl-auto-height="false"
              >
                <div className="rd-navbar-main-outer">
                  <div className="rd-navbar-main">
                    <div className="rd-navbar-panel">
                      <div className="rd-navbar-brand">
                        <div className="brand">
                          <img
                            className="brand-logo-light"
                            src="/assets/images/Logo.svg"
                            alt=""
                            width="222"
                            height="44"
                            style={{
                              background: 'transparent',
                              borderRadius: '50px',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="rd-navbar-main-element">
                      <div className="rd-navbar-nav-wrap toggle-original-elements">
                        <ul className="rd-navbar-nav">
                          {localStorage.getItem('access_token') &&
                          userDetails ? (
                            <>
                              <li className="rd-nav-item">
                                <div
                                  className="rd-nav-link"
                                  style={{
                                    color: '#2c9886',
                                    fontFamily: '"Fredoka One", cursive',
                                    fontWeight: '400',
                                  }}
                                >
                                  Balance: {userDetails.balance / 100}
                                </div>
                              </li>
                              <li className="rd-nav-item">
                                <div
                                  className="rd-nav-link"
                                  style={{
                                    color: '#2c9886',
                                    fontFamily: '"Fredoka One", cursive',
                                    fontWeight: '400',
                                  }}
                                  onClick={() => navigate('/profile')}
                                >
                                  Profile
                                </div>
                              </li>
                              <li className="rd-nav-item">
                                <div
                                  className="rd-nav-link"
                                  style={{
                                    color: '#2c9886',
                                    fontFamily: '"Fredoka One", cursive',
                                    fontWeight: '400',
                                  }}
                                  onClick={() => {
                                    localStorage.removeItem('access_token');
                                    localStorage.removeItem('refresh_token');
                                    navigate('/login');
                                  }}
                                >
                                  Sign out
                                </div>
                              </li>
                            </>
                          ) : (
                            <>
                              <li className="rd-nav-item">
                                <div
                                  className="rd-nav-link"
                                  style={{
                                    color: '#2c9886',
                                    fontFamily: '"Fredoka One", cursive',
                                    fontWeight: '400',
                                  }}
                                  onClick={() => navigate('/login')}
                                >
                                  Login
                                </div>
                              </li>
                              <li className="rd-nav-item">
                                <div
                                  className="rd-nav-link"
                                  style={{
                                    color: '#2c9886',
                                    fontFamily: '"Fredoka One", cursive',
                                    fontWeight: '400',
                                  }}
                                  onClick={() => navigate('/register')}
                                >
                                  Register
                                </div>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="christmas-border" />
              </nav>
            </div>
            <section
              className="swiper-container swiper-slider swiper-slider-3 context-dark swiper-container-initialized swiper-container-horizontal"
              data-loop="true"
            >
              <div
                className="swiper-wrapper"
                style={{
                  transform: 'translate3d(-1903px, 0px, 0px)',
                  transitionDuration: '0ms',
                }}
              >
                <div
                  className="swiper-slide novi-bg swiper-slide-duplicate swiper-slide-prev swiper-slide-duplicate-next"
                  style={{
                    backgroundColor: 'rgb(248, 29, 61)',
                    width: '1903px',
                  }}
                  data-swiper-slide-index="1"
                >
                  <div className="particles-js" id="particles-js-2">
                    <canvas
                      className="particles-js-canvas-el"
                      style={{ width: '100%', height: '100%' }}
                    ></canvas>
                  </div>
                  <div className="swiper-slide-caption section-inset-27 text-center">
                    <div className="container">
                      <div className="row row-fix justify-content-center">
                        <div className="col-xl-9">
                          <img
                            src="/assets/images/landing-christmas-03-135x143.png"
                            alt=""
                            width="135"
                            height="143"
                            data-caption-animate="fadeInUp"
                            data-caption-delay="100"
                            className="not-animated"
                          />
                          <div
                            className="heading-1 not-animated"
                            data-caption-animate="fadeInUp"
                            data-caption-delay="250"
                          >
                            Best Christmas Experience
                          </div>
                          <p
                            className="big not-animated"
                            data-caption-animate="fadeInUp"
                            data-caption-delay="400"
                          >
                            Get unforgettable impressions from this year’s
                            <br className="d-none d-xl-block" />
                            Christmas Event at NY Entertainment Center.
                          </p>
                          <div
                            className="button button-secondary button-width-1 button-nuka not-animated"
                            data-custom-scroll-to="book-tickets"
                            data-caption-animate="fadeInUp"
                            data-caption-delay="650"
                            onClick={() => navigate('/register')}
                          >
                            Register now!
                            <span className="button-overlay"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="swiper-slide swiper-slide-active"
                  data-slide-bg="/assets/images/landing-christmas-01-1920x1080.jpg"
                  data-swiper-slide-index="0"
                  style={{
                    width: '1903px',
                    backgroundImage:
                      "url('/assets/images/landing-christmas-01-1920x1080.jpg')",
                  }}
                >
                  <div className="particles-js" id="particles-js-1">
                    <canvas
                      className="particles-js-canvas-el"
                      style={{ width: '100%', height: '100%' }}
                      width="1903"
                      height="1084"
                    ></canvas>
                  </div>
                  <div className="swiper-slide-caption section-inset-27">
                    <div className="container">
                      <div className="row row-fix row-30 justify-content-between flex-md-row-reverse align-items-md-center align-items-xl-start">
                        <div className="col-sm-10 col-md-6 col-xl-7 col-xxl-7">
                          <div className="pl-md-3 pl-lg-0">
                            <h1
                              className="title-christmas fadeInUp animated"
                              data-caption-animate="fadeInUp"
                              data-caption-delay="100"
                            >
                              <span>
                                <img
                                  src="/assets/images/landing-christmas-02-195x182.png"
                                  alt=""
                                  width="195"
                                  height="182"
                                />
                                H
                              </span>
                              oliday Presents Made Simple
                            </h1>
                            <p
                              className="big fadeInUp animated"
                              data-caption-animate="fadeInUp"
                              data-caption-delay="250"
                            >
                              Gift happy memories to your loved ones.
                              <br className="d-none d-xl-block" />
                              Simple solution for a warm surprise.
                            </p>
                            <div
                              className="countdown is-countdown fadeInUp animated"
                              data-caption-animate="fadeInUp"
                              data-caption-delay="400"
                              data-time="22 Aug 2020 14:00"
                              data-format="dhms"
                              data-type="until"
                            >
                              <span className="countdown-row countdown-show4">
                                <span className="countdown-section">
                                  <span className="countdown-amount">
                                    {timeLeft.days}
                                  </span>
                                  <span className="countdown-period">Days</span>
                                </span>
                                <span className="countdown-section">
                                  <span className="countdown-amount">
                                    {timeLeft.hours}
                                  </span>
                                  <span className="countdown-period">
                                    Hours
                                  </span>
                                </span>
                                <span className="countdown-section">
                                  <span className="countdown-amount">
                                    {timeLeft.minutes}
                                  </span>
                                  <span className="countdown-period">
                                    Minutes
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-xl-5 col-xxl-4">
                          <div
                            className="box-form-2 novi-bg fadeInRight animated"
                            data-caption-animate="fadeInRight"
                            data-caption-delay="100"
                          >
                            <h4>Start your journey</h4>
                            <button
                              className="button button-block button-secondary button-nuka"
                              style={{ marginBottom: '30px' }}
                              type="button"
                              onClick={() =>
                                navigate(userDetails ? '/profile' : '/register')
                              }
                            >
                              {localStorage.getItem('access_token') &&
                              userDetails ? (
                                <span>Go to Profile!</span>
                              ) : (
                                <span>Register Now!</span>
                              )}
                              <span className="button-overlay"></span>
                            </button>
                            <div className="unit unit-spacing-6 align-items-center">
                              <div className="unit-left">
                                <svg
                                  width="30"
                                  height="30"
                                  viewBox="0 0 30 30"
                                  fill="none"
                                  xmlns="https://www.w3.org/2000/svg"
                                >
                                  <path d="M15 0C12.9297 0 10.9863 0.400391 9.16992 1.20117C7.35352 1.98242 5.76172 3.05664 4.39453 4.42383C3.04688 5.77148 1.97266 7.35352 1.17188 9.16992C0.390625 10.9863 0 12.9297 0 15C0 17.0703 0.390625 19.0137 1.17188 20.8301C1.97266 22.6465 3.04688 24.2383 4.39453 25.6055C5.76172 26.9531 7.35352 28.0176 9.16992 28.7988C10.9863 29.5996 12.9297 30 15 30C17.0703 30 19.0137 29.5996 20.8301 28.7988C22.6465 28.0176 24.2285 26.9531 25.5762 25.6055C26.9434 24.2383 28.0176 22.6465 28.7988 20.8301C29.5996 19.0137 30 17.0703 30 15C30 12.9297 29.5996 10.9863 28.7988 9.16992C28.0176 7.35352 26.9434 5.77148 25.5762 4.42383C24.2285 3.05664 22.6465 1.98242 20.8301 1.20117C19.0137 0.400391 17.0703 0 15 0ZM15 4.6875C15.5078 4.6875 15.9473 4.87305 16.3184 5.24414C16.6895 5.61523 16.875 6.05469 16.875 6.5625C16.875 7.07031 16.6895 7.50977 16.3184 7.88086C15.9473 8.25195 15.5078 8.4375 15 8.4375C14.4922 8.4375 14.0527 8.25195 13.6816 7.88086C13.3105 7.50977 13.125 7.07031 13.125 6.5625C13.125 6.05469 13.3105 5.61523 13.6816 5.24414C14.0527 4.87305 14.4922 4.6875 15 4.6875ZM18.75 25.3125H11.25C10.9766 25.3125 10.752 25.2246 10.5762 25.0488C10.4004 24.873 10.3125 24.6484 10.3125 24.375C10.3125 24.1016 10.4004 23.877 10.5762 23.7012C10.752 23.5254 10.9766 23.4375 11.25 23.4375H14.0625V13.125H11.25C10.9766 13.125 10.752 13.0371 10.5762 12.8613C10.4004 12.6855 10.3125 12.4609 10.3125 12.1875C10.3125 11.9141 10.4004 11.6895 10.5762 11.5137C10.752 11.3379 10.9766 11.25 11.25 11.25H15C15.2734 11.25 15.498 11.3379 15.6738 11.5137C15.8496 11.6895 15.9375 11.9141 15.9375 12.1875V23.4375H18.75C19.0234 23.4375 19.248 23.5254 19.4238 23.7012C19.5996 23.877 19.6875 24.1016 19.6875 24.375C19.6875 24.6484 19.5996 24.873 19.4238 25.0488C19.248 25.2246 19.0234 25.3125 18.75 25.3125Z"></path>
                                </svg>
                              </div>
                              <div className="unit-body">
                                <p className="small">
                                  We guarantee your privacy and do not share
                                  your data with third parties.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="swiper-slide novi-bg swiper-slide-next swiper-slide-duplicate-prev"
                  style={{
                    backgroundColor: 'rgb(248, 29, 61)',
                    width: '1903px',
                  }}
                  data-swiper-slide-index="1"
                >
                  <div className="particles-js" id="particles-js-2">
                    <canvas
                      className="particles-js-canvas-el"
                      style={{ width: '100%', height: '100%' }}
                      width="1903"
                      height="1084"
                    ></canvas>
                  </div>
                  <div className="swiper-slide-caption section-inset-27 text-center">
                    <div className="container">
                      <div className="row row-fix justify-content-center">
                        <div className="col-xl-9">
                          <img
                            src="/assets/images/landing-christmas-03-135x143.png"
                            alt=""
                            width="135"
                            height="143"
                            data-caption-animate="fadeInUp"
                            data-caption-delay="100"
                            className="not-animated"
                          />
                          <div
                            className="heading-1 not-animated"
                            data-caption-animate="fadeInUp"
                            data-caption-delay="250"
                          >
                            Gifts Made Simple
                          </div>
                          <p
                            className="big not-animated"
                            data-caption-animate="fadeInUp"
                            data-caption-delay="400"
                          >
                            Send a heartwarming gift to your loved ones.
                            <br className="d-none d-xl-block" />
                            Because memories and holiday spirit matter.
                          </p>
                          <a
                            className="button button-secondary button-width-1 button-nuka not-animated"
                            href="#"
                            data-custom-scroll-to="book-tickets"
                            data-caption-animate="fadeInUp"
                            data-caption-delay="650"
                          >
                            Register now!
                            <span className="button-overlay"></span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
                <span
                  className="swiper-pagination-bullet swiper-pagination-bullet-active"
                  tabIndex={0}
                  role="button"
                  aria-label="Go to slide 1"
                ></span>
                <span
                  className="swiper-pagination-bullet"
                  tabIndex={0}
                  role="button"
                  aria-label="Go to slide 2"
                ></span>
              </div>
              <div
                className="swiper-button-prev"
                tabIndex={0}
                role="button"
                aria-label="Previous slide"
              >
                <svg
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="M10.3634 0L0 10L10.3634 20L14 16.4909L7.27327 10L14 3.50913L10.3634 0Z"></path>
                </svg>
              </div>
              <div
                className="swiper-button-next"
                tabIndex={0}
                role="button"
                aria-label="Next slide"
              >
                <svg
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <path d="M3.63664 0L14 10L3.63664 20L0 16.4909L6.72673 10L0 3.50913L3.63664 0Z"></path>
                </svg>
              </div>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              ></span>
            </section>
          </header>

          <section
            className="section novi-bg novi-bg-img section-md bg-default novi-bg novi-bg-img"
            id="about"
          >
            <div className="container">
              <div className="row row-40 align-items-center">
                <div className="col-lg-6 col-xl-5 wow fadeInRight">
                  <h2>About Us</h2>
                  <p className="big">
                    Our Mission At Memora is to revolutionize the way people
                    give gifts. Whether it’s Christmas, a birthday, Mother’s
                    Day, or any celebration in between, we use cutting-edge AI
                    technology to understand your needs and recommend the most
                    thoughtful and unique gifts. No more generic presents – just
                    meaningful surprises that bring joy and connection.{' '}
                  </p>
                </div>
                <div className="col-lg-6 col-xl-7 wow fadeInLeft">
                  <img
                    src="/assets/images/landing-christmas-05-720x490.jpg"
                    alt=""
                    width="720"
                    height="490"
                  />
                </div>
              </div>
            </div>
          </section>
          <section
            className="section novi-bg novi-bg-img section-md section-md-10 bg-gray-100 position-relative"
            id="activities"
          >
            <div className="particles-js" id="particles-js-4">
              <canvas
                className="particles-js-canvas-el"
                style={{ width: '100%', height: '100%' }}
                width="1903"
                height="1313"
              ></canvas>
            </div>
            <div className="container position-relative">
              <div className="text-center wow fadeInUp">
                <h2>Our Work</h2>
                <p className="big">
                  Take a more detailed look at what we have to offer
                </p>
              </div>
              <div className="card-activity-group">
                <div className="card-activity row row-fix row-30 align-items-center justify-content-center wow fadeInUp">
                  <div className="col-md-10 col-lg-6">
                    <img
                      className="card-activity-image"
                      src="/assets/images/landing-christmas-11-610x415.jpg"
                      alt=""
                      width="610"
                      height="415"
                    />
                  </div>
                  <div className="col-md-10 col-lg-6">
                    <div className="card-activity-body">
                      <h4 className="card-activity-title">
                        <div>Video</div>
                      </h4>
                      <p className="big card-activity-text">
                        You choose from one of the video templates we have,
                        provide the values you want to be in the template and
                        using AI we will handle the rest.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card-activity row row-fix row-30 align-items-center justify-content-center card-activity-revers wow fadeInUp">
                  <div className="col-md-10 col-lg-6">
                    <img
                      className="card-activity-image"
                      src="/assets/images/landing-christmas-12-610x415.jpg"
                      alt=""
                      width="610"
                      height="415"
                    />
                  </div>
                  <div className="col-md-10 col-lg-6">
                    <div className="card-activity-body">
                      <h4 className="card-activity-title">
                        <a href="#">Image</a>
                      </h4>
                      <p className="big card-activity-text">
                        We also provide Image template for the ones that love to
                        simply provide emotions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section novi-bg novi-bg-img section-md bg-default">
            <div className="container">
              <div className="text-center wow fadeInUp">
                <h2>Common Questions</h2>
                <p className="big">
                  Here you can find the answers to the most frequently asked
                  questions about us.
                </p>
              </div>
              <div className="row row-xxl-50 row-40 offset-xl-top-72 justify-content-center justify-content-lg-start">
                <div className="col-md-10 col-lg-6 wow fadeInUp">
                  <h4>How can we share the generated gift?</h4>
                  <p>
                    You can share the link provided for preview in your
                    generated tab or even download the gift itself and send it
                    as a file.
                  </p>
                </div>
                <div className="col-md-10 col-lg-6 wow fadeInUp">
                  <h4>Can we add templates?</h4>
                  <p>
                    Yes, templates can be added by a personal request to us. If
                    you got any template in mind you would want feel free to
                    reach out to us and we will do our best to add it to the
                    list of templates.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <footer
            className="section novi-bg novi-bg-img footer-christmas section-md section-md-2 bg-primary"
            id="ask-question"
          >
            <div className="container">
              <div className="row row-fix row-50 justify-content-center justify-content-xl-center align-items-xl-center flex-lg-row-reverse">
                <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 wow fadeInRight">
                  <h2>Contact Us</h2>
                  <p className="big">
                    Feel free to get in touch with us to ask a question or leave
                    a testimonial about our Christmas event.
                  </p>
                  <div className="info-group">
                    <div className="info-item">
                      <div className="info-header">
                        <svg
                          width="28"
                          height="37"
                          viewBox="0 0 28 37"
                          fill="none"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <path d="M14 37C14.1701 37 14.316 36.9627 14.4375 36.8881C14.5833 36.8384 14.7049 36.7513 14.8021 36.627C14.9479 36.4778 15.7135 35.6448 17.099 34.128C18.5087 32.5864 20.0156 30.709 21.6198 28.496C23.224 26.2829 24.6823 23.9083 25.9948 21.372C27.3316 18.8108 28 16.461 28 14.3226C28 12.0847 27.5868 10.083 26.7604 8.31754C25.9583 6.55208 24.901 5.06015 23.5885 3.84173C22.276 2.59845 20.7812 1.65356 19.1042 1.00706C17.4271 0.335685 15.7257 0 14 0C12.2743 0 10.5729 0.323253 8.89583 0.969758C7.24306 1.61626 5.74826 2.56116 4.41146 3.80444C3.09896 5.02285 2.02951 6.52722 1.20312 8.31754C0.401042 10.083 0 12.0847 0 14.3226C0 16.4362 0.65625 18.7611 1.96875 21.2974C3.30556 23.8337 4.77604 26.2208 6.38021 28.4587C7.98438 30.6717 9.47917 32.5491 10.8646 34.0907C12.2743 35.6324 13.0521 36.4778 13.1979 36.627C13.2951 36.7513 13.4045 36.8384 13.526 36.8881C13.6719 36.9627 13.8299 37 14 37ZM9.33333 14.3226C9.33333 13.0047 9.78299 11.8858 10.6823 10.9657C11.6059 10.0208 12.7118 9.54839 14 9.54839C15.2882 9.54839 16.3819 10.0208 17.2812 10.9657C18.2049 11.8858 18.6667 13.0047 18.6667 14.3226C18.6667 15.6405 18.2049 16.7718 17.2812 17.7167C16.3819 18.6368 15.2882 19.0968 14 19.0968C12.7118 19.0968 11.6059 18.6368 10.6823 17.7167C9.78299 16.7718 9.33333 15.6405 9.33333 14.3226Z"></path>
                        </svg>
                        <h5 className="info-title">
                          Email: account@memora.love
                        </h5>
                      </div>
                    </div>
                  </div>
                  <p className="rights">
                    <span>©&nbsp;</span>
                    <span className="copyright-year">2024</span>
                    <span>.&nbsp;</span>
                    <span>Memora</span>
                    <span>.&nbsp;</span>
                    <span>All Rights Reserved</span>
                  </p>
                  <div
                    style={{
                      marginTop: '15px',
                      fontSize: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/terms-and-conditions`,
                      )
                    }
                  >
                    Terms and Conditions
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default MainScreen;
