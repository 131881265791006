import { QueryClient } from '@tanstack/react-query';

import type { QueryClientConfig } from '@tanstack/react-query';

export const queryConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
};

const queryClient = new QueryClient(queryConfig);

export default queryClient;
